import React, { useState } from 'react';
import { useLocation, useParams, Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { t } from '../../i18n/customi18n.js';
import { useCookies } from 'react-cookie';
import { LazyImage } from '../commonComponent';
import { isEnglish } from '../../common/const.js'

const LanguageLink = React.memo((props) => {
    const spanStyle = "block mx-2 font-roboto text-lg inline-flex flex-row items-center space-x-2"
    const linkStyle = "block mx-2 font-roboto text-lg inline-flex flex-row items-center space-x-2 hover:opacity-50 duration-200 underline decoration-1 underline-offset-4"
    return (
      <>
        {props.isLink? 
        (<a className={linkStyle} href={props.href}>{props.children}</a>): 
        (<span className={spanStyle}>{props.children}</span>)
        }
      </>
    )
  })
LanguageLink.displayName = 'LanguageLink'

function Header() {
    let { area } = useParams();
    const isRoot = useLocation().pathname == "/"? true: false;
    const [cookies] = useCookies(["area"])

    const [isShowMenu, setIsShowMenu] = useState(false)
    const tapMenu = () => {
        setIsShowMenu(!isShowMenu)
    }

    // const { t } = useTranslation();

    return (
        <div className="h-16 w-full bg-white flex justify-between z-10 relative shadow-sm">
        <Link className="flex items-center pl-2" to="/">
            <img className="h-8 w-40" src="/img/logo.svg" alt={t("alt.header.logo")}/>
        </Link>
        {
            (() => {
            if (!isRoot && cookies.area || area) {
                return (
                    <h1 className="flex flex-grow items-center">
                    <Link to={"/" + (area? area: cookies.area) + "/jobslist"} className="h-full">
                        <span className="font-roboto font-black px-2 py-1 bg-gray-700 text-white text-xs rounded-sm ml-2 inline-block mt-5">{t(`top.area.${area? area: cookies.area}`)}</span>
                    </Link>
                    </h1>
                )
            }
            })()
        }

        <div className="h-16 w-16 right-0 relative">
            <button className="h-16 w-16 flex items-center justify-center right-0 bg-[#FF7334]" aria-label={t("aria.openmenu")} onClick={tapMenu}>
            <LazyImage className="w-8 h-5" src="/img/humburger.svg" alt={t("aria.openmenu")}/>
            </button>
            <div className={isShowMenu? "w-72 h-96 absolute top-1 right-1 border border-solid rounded-lg flex items-center justify-center text-white z-50 duration-100 bg-[#FF7334]": "hidden w-72 h-96 absolute top-1 right-1 border-1 border-solid rounded-lg flex items-center justify-center text-white z-50 duration-100 bg-[#EE7B5E]"}>
            <button className="absolute top-0 h-16 w-16 flex items-center justify-center right-0" aria-label={t("aria.closemenu")} onClick={tapMenu}>
                <LazyImage className="w-8 rotate-45" src="/img/plus.svg" alt={t("aria.closemenu")}/>
            </button>
            <ul className="flex flex-col divide-y divide-white divide-dotted">
                <li className="text-white text-center py-3 px-12 pt-4">
                <Link className="font-roboto text-lg inline-flex flex-row items-center space-x-2 hover:opacity-50 duration-200" to="/keepList" onClick={tapMenu}>
                    <LazyImage className="w-8 m-0 opacity-80" src="/img/keep.svg" />
                    <span>{t('header.keep')}</span>
                </Link>
                </li>
                <li className="text-white text-center py-3 px-12 pt-4">
                <Link className="font-roboto text-lg inline-flex flex-row items-center space-x-2 hover:opacity-50 duration-200" to="/viewList" onClick={tapMenu}>
                    <LazyImage className="w-8 m-0 opacity-80" src="/img/history.svg" />
                    <span>{t('header.history')}</span>
                </Link>
                </li>
                <li className="text-white text-center py-3 px-12 pt-4">
                <Link className="font-roboto text-lg inline-flex flex-row items-center space-x-2 hover:opacity-50 duration-200 " to="/contact" onClick={tapMenu}>
                    <LazyImage className="w-8 m-0 opacity-80" src="/img/contact.svg" />
                    <span>{t('header.contact')}</span>
                </Link>
                </li>
                <li className="text-white text-center py-3 px-12 pt-4">
                <LazyImage className="w-8 m-0 opacity-80 inline" src="/assets/language.svg" />
                <LanguageLink isLink={isEnglish} href={"https://mangojob.me"}>{t('header.language.th')}</LanguageLink>
                |
                <LanguageLink isLink={!isEnglish} href={"https://en.mangojob.me"}>{t('header.language.en')}</LanguageLink>
                </li>
            </ul>
            </div>
        </div>
        </div>
    )
}

export default Header