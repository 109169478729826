// Head.js

// import { useTranslation } from 'react-i18next';
import { t } from "../i18n/customi18n.js";

import * as React from "react"
// react-helmetをimportするのはこのコンポーネントだけになります
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { isEnglish, getOccupationName, getSalaryName, getSubAreaName } from '../common/const.js'

const getStrs = (t, area, subarea, occupation, salary, index) => {
  let subAreaName = getSubAreaName(subarea)
  let areaStr = isEnglish? (area && subAreaName? `${subAreaName}, ${area}`: (subAreaName? `${subAreaName}`: `${area? area: ''}`)): `${subAreaName? subAreaName: ''}${area? area: ''}`

  let occupationName = getOccupationName(occupation)
  // 英語の場合はスペースを追加
  occupationName = occupationName != '' ? (occupationName + (isEnglish? ' ': '')): ''

  let salaryName = getSalaryName(salary)
  // 英語の場合はスペースを追加
  salaryName = salaryName != '' ? (salaryName + (isEnglish? ' ': '')): ''

  let page = index && index > 0 ? t("head.content.page", {index: (parseInt(index) + 1)}): ''

  return {
    areaStr: areaStr,
    occupationName: occupationName,
    salaryName: salaryName,
    pageIndex: page
  }
}

const getMetaDescription = (t, pretitle, area, store, subarea, occupation, salary, index) =>{
  const {areaStr, occupationName, salaryName, pageIndex} = getStrs(t, area, subarea, occupation, salary, index)

  if(store){
    return t("head.description.store", {area: areaStr, occupation: occupationName, salary: salaryName, store: store?? ''})
  }
  else if(pretitle && pretitle != "" && area && area != "") {
    return pretitle.replace(/\{\{(\w+)\}\}/g, " - " + area) + t("head.title.other.title")
  }
  else if(area && area != ""){
    return t("head.description.area", {area: areaStr, occupation: occupationName, salary: salaryName, page: pageIndex})
  }
  else{
    return pretitle.replace(/\{\{(\w+)\}\}/g, "") + t("head.title.other.title")
  }
}

//TODO:
const getMetaImage = (image) => {
  if(image && image != ""){
    return getUrl(image)
  }
  else{
    //TODO: OGP画像
    return getDomain() + "/assets/ogp.jpg"
  }
}

const getMetaTitle = (t, pretitle, area, store, subarea, occupation, salary, index) => {
  const {areaStr, occupationName, salaryName, pageIndex} = getStrs(t, area, subarea, occupation, salary, index)

  if(store && store != ""){
    return t("head.title.store", {area: areaStr, occupation: occupationName, salary: salaryName, store: store})
  }
  else if(pretitle && pretitle != "" && area && area != "") {
    return pretitle.replace(/\{\{(\w+)\}\}/g, " - " + area) + t("head.title.other.title")
  }
  else if(area && area != ""){
    return t("head.title.area", {area: areaStr, occupation: occupationName, salary: salaryName, page: pageIndex})
  }
  else {
    return pretitle.replace(/\{\{(\w+)\}\}/g, "") + t("head.title.other.title")
  }
}

const getIndex = (noindex) => {
  if(noindex){
    return "noindex,follow,noodp"
  }
  else{
    return "index,follow"
  }
}

const getDomain = () => {
  return isEnglish? "https://en.mangojob.me" : "https://mangojob.me"
}

const getUrl = (pathname) => {
  if(pathname.match(/https/)) {
    return pathname
  }
  return getDomain() + pathname
}

const getCanonicalUrl = (pathname, search) => {
  const basePath = pathname.endsWith('/')
  ? pathname.slice(0, -1)
  : pathname;
  const queryString = search;

  const normalizedPath = getDomain() + basePath + queryString;

  return normalizedPath
}

// ページコンポーネントから、titleをpropしています
const HeadWithOgp = (props) => {
  // urlからareaパラメータの取得を行う
  // sitemap/area, area/jobslistのどちらかの場合値が入るが、
  // sitemap/areaの場合のみURLから取得された値が利用される
  let { area } = useParams();
  area = area == "" || area == null ? props.area : t("top.area." + area.toLowerCase())
  const {store, image, noindex, pretitle, subarea, thumbnail, index, images} = props
  const location = useLocation()
  // const { t } = useTranslation();

  // TODO: propsから渡されたoccupationや給与があればそれを優先。店舗詳細用
  const [searchParams] = useSearchParams();
  const occupation = searchParams.get('occupation')
  const salary = searchParams.get('salary')

  return (
    <Helmet>
      <title>{getMetaTitle(t, (pretitle? pretitle: ""), area, store, subarea, occupation, salary, index)}</title>
      <meta name="description" content={getMetaDescription(t, (pretitle? pretitle: ""), area, store, subarea, occupation, salary, index)} />
      <meta name="robots" content={getIndex(noindex)} />
      {/* OGP Facebook */}
      <meta property="og:url" content={getUrl(location.pathname)} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={getMetaTitle(t, (pretitle? pretitle: ""), area, store, subarea, occupation, salary, index)} />
      <meta property="og:description" content={getMetaDescription(t, (pretitle? pretitle: ""), area, store, subarea, occupation, salary, index)} />
      <meta property="og:site_name" content="MangoJob" />
      <meta property="og:image" content={getMetaImage(image)} />
      {/* OGP Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@mangojob" />
      {thumbnail && (<meta name="thumbnail" content={getUrl(thumbnail.url)}></meta>)}
      {
        images && (() => {
          const firstNonThumbnailImage = images.find((img) => !img.isThumbnail);
          return firstNonThumbnailImage && <link rel="preload" href={firstNonThumbnailImage.url} as="image" />;
        })()
      }
      <link rel="canonical" href={getCanonicalUrl(location.pathname, location.search)} />
    </ Helmet>
  )
}

const Head = React.memo((props) => {
  const {area, store, pretitle, noindex} = props
  const location = useLocation()
  // const { t } = useTranslation();

  return (
    <Helmet>
      <title>{getMetaTitle(t, (pretitle? pretitle: ""), area, store)}</title>
      <meta name="description" content={getMetaDescription(t, (pretitle? pretitle: ""), area, store)} />
      <meta name="robots" content={getIndex(noindex)}  />
      <link rel="canonical" href={getCanonicalUrl(location.pathname, location.search)} />
    </ Helmet>
  )
})
Head.displayName = 'Head'

const OwnerHeadWithOgp = (props) => {
  const {noindex} = props
  const location = useLocation()
  // const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t("head.title.owner.title")}</title>
      <meta name="description" content={t("head.description.owner.title")} />
      <meta name="robots" content={getIndex(noindex)} />
      {/* OGP Facebook */}
      <meta property="og:url" content={getUrl(location.pathname)} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t("head.title.owner.title")} />
      <meta property="og:description" content={t("head.description.owner.title")} />
      <meta property="og:site_name" content="MangoJob" />
      <meta property="og:image" content={getDomain() + "/assets/store_ogp.jpg"} />
      {/* OGP Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@mangojob" />

      <link rel="canonical" href={getCanonicalUrl(location.pathname, location.search)} />
    </ Helmet>
  )
}

export {HeadWithOgp, Head, OwnerHeadWithOgp}
