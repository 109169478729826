// i18n.js
import language from './language.json';

export function t(key, placeholders = {}) {
    // キーをドットで分割
    const keys = key.split('.');
    // 言語オブジェクトを取得
    let translation = language;

    // キーの階層を下りながらアクセス
    for (const k of keys) {
    if (translation[k] !== undefined) {
        translation = translation[k];
    } else {
        // キーが存在しない場合、元のキーを使う
        translation = key;
        break;
    }
    }

    // プレースホルダーの置き換え
    return translation.replace(/\{\{(\w+)\}\}/g, (match, placeholder) => {
    const replacement = placeholders[placeholder];
    return replacement !== undefined ? replacement : match;
    });
}