// reducers.js
import { FETCH_JOBS_SUCCESS, FETCH_JOBS_FAILURE } from '../action/fetchJobActions.js';

const initialState = {
  jobs: {},
  error: null,
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_SUCCESS:
      var job = {};
      job[action.path] = action.payload
      return {
        ...initialState,  // 初期状態にリセットする
        jobs: {...state.jobs, ...job}
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...initialState,  // 初期状態にリセットする
        error: action.payload,
      };
    default:
      return state;
  }
};

export default jobsReducer;
