import React from 'react';
// eslint-disable-next-line no-unused-vars
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

const LazyImage = React.memo((props) => {
    return (
        <>
            <img data-src={props.src} className={props.className + ' lazyload'} alt={props.alt}/>
        </>
    )
})
LazyImage.displayName = 'LazyImage'

const SectionHeader = React.memo((props) => {
    return (
        <>
            <header className="border-l-8 border-corporait border-solid bg-white">
                <h2 className="p-2">
                    <span className="font-bold text-xl">{props.title}</span>
                    <span className="ml-2.5">{props.subTitle}</span>
                </h2>
            </header>
        </>
    )
})
SectionHeader.displayName = 'SectionHeader'

const IndicatorButton = React.memo((props) => {
    return (
        <button type="submit" className={props.className + " flex justify-center "} onSubmit={props.onSubmit}  disabled={props.loading} >
            {!props.loading ? props.children: <div className="animate-spin h-7 w-7 border-4 border-white rounded-full border-t-transparent"></div>}
        </button>
    )
})
IndicatorButton.displayName = 'IndicatorButton'

const Lines = React.memo((props) => {
    const words = props.words? props.words.split('</br>'): []

    return (
        <>
        {
            (() => {
            const items = [];
            for (let i = 0; i < words.length; i++) {
                items.push(<p key={i} className={props.margin? "mb-2": ""}>{words[i]}</p>)
            }
            return <>{items}</>;
            })()
        }
        </>
    )
})
Lines.displayName = 'Lines'

export {SectionHeader, IndicatorButton, Lines, LazyImage}