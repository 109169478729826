import React from 'react';

const SkeltonDetailContent = React.memo(() => {
    return (
        <>
        <div className="h-10 w-full bg-gray-300"></div>
        <div className="mt-2 mb-4 mx-4">
            <div className="h-5 mb-1 w-1/5 rounded bg-gray-300"></div>
            <div className="h-4 mb-1 w-full rounded bg-gray-300"></div>
            <div className="h-4 mb-1 w-1/4 rounded bg-gray-300"></div>
            <div className="h-4 mb-1 w-3/4 rounded bg-gray-300"></div>
        </div>
        </>
    )
})
SkeltonDetailContent.displayName = 'SkeltonDetailContent'

const SkeltonFullJobCard = React.memo(() => {
    return (
        <>
            <div>
                <div className="w-full h-8"></div>
            </div>
            <section className="relative mt-4 mb-8">
                <div className="animate-pulse">
                    <div className="mt-5 h-4 mb-1 mx-4 bg-gray-300 w-1/4 rounded"></div>
                    <div className="mb-2 mx-4 h-6 mb:h-8 rounded bg-gray-300 w-1/2"></div>
                    <div className="flex mx-4 mb-6 gap-4">
                        <div className="h-4 bg-gray-300 w-1/4 rounded"></div>
                        <div className="h-4 bg-gray-300 w-1/2 rounded"></div>
                    </div>
                    <div className="h-[218px] rounded w-3/4 bg-gray-300 mx-auto"></div>
                    <div className="h-6 mb-8 mx-auto w-1/2 mt-4 content-center bg-gray-300  rounded"></div>
                    <SkeltonDetailContent />
                    <SkeltonDetailContent />
                    <SkeltonDetailContent />
                </div>
            </section>
        </>
    );
})
SkeltonFullJobCard.displayName = 'SkeltonFullJobCard'

export {SkeltonFullJobCard}