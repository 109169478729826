function isObject(val) {
    if( val !== null
     && typeof(val) === 'object'
     && val.constructor === Object ) {
      return true;
    }
    return false;
}

const Analytics = {
    sendEventWithLocation: (eventName, params) => {
        const location = window.location
        const path = location.pathname + location.search
        if(isObject(params)) {
            params.path = path
            window.gtag('event', eventName, params);
        } else {
            window.gtag('event', eventName, {"path": path});
        }
    }
  }
  
  export {Analytics}