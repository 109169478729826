import React from 'react';

const SkeltonSearchArea = React.memo(() => {
    return (
        <div className="px-4 py-4 h-[237px] bg-gray-100">
        </div>
    );
})
SkeltonSearchArea.displayName = 'SkeltonSearchArea'

export { SkeltonSearchArea }