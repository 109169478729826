import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import { Outlet } from "react-router-dom";

const CommonPageLayout = () => (
  <>
    <Header />
      <Outlet />
    <Footer />
  </>
);

export default CommonPageLayout;