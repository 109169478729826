import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { t } from '../../i18n/customi18n';
import SNSAccount from '../multiPage/snsAccount';

const FooterTopLink = ({ title, links }) => {
    const [isUlVisible, setIsUlVisible] = useState(false);
  
    const toggleUlVisibility = (event) => {
        event.preventDefault();
        setIsUlVisible(!isUlVisible);
    };
  
    return (
      <div className="text-sm border-t bg-gray-50">
        <h2 onClick={toggleUlVisibility} className="px-4 py-2">
          <a href="#" className="cursor-pointer">{title}</a>
        </h2>
        <div className={`px-4 ${isUlVisible ? 'block' : 'hidden'}`}>
          <ul className="flex flex-wrap py-2 gap-y-2 gap-x-2 text-xs border-t border-dotted">
            {links.map(link => (
              <li key={link.href}>
                <a href={link.href} className="block underline decoration-1 underline-offset-4 py-1">{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

function Footer() {
    // const { t } = useTranslation();
    const [breakElements, setBreakElements] = useState([]);
    const ulRef = useRef(null);

    const links = [
        { href: '/articles/hiregirls', text: t('footer.articles.hire.links.hiregirls.title') }
    ];

    useEffect(() => {
        const detectBreaks = () => {
        if (ulRef.current) {
            const items = Array.from(ulRef.current.children);
            let currentTop = items[0].getBoundingClientRect().top;
            let breaks = [0];

            for (let i = 1; i < items.length; i++) {
            const itemTop = items[i].getBoundingClientRect().top;
            if (itemTop > currentTop) {
                breaks.push(i);
                currentTop = itemTop;
            }
            }

            setBreakElements(breaks);
        }
        };

        detectBreaks();
    }, []);

    return (
        <>
            <FooterTopLink title={t('footer.articles.hire.title')} links={links}/>
            <footer className="py-6 text-center md:py-8 bg-gray-900 text-white">
                <SNSAccount/>
            <ul className="text-xs flex flex-wrap items-center mb-4 justify-center gap-y-2" ref={ulRef}>
                {['privacypolicy', 'terms', 'contact', 'sitemap'].map((link, index) => (
                <li key={index} className={`px-2 border-gray-500 ${breakElements.includes(index)? 'border-l-0': 'border-l'}`}
                >
                    <Link className="hover:opacity-50 duration-200 block py-1" to={`/${link}`}>
                    {t(`footer.${link}`)}
                    </Link>
                </li>
                ))}
            </ul>
            <p className="text-center font-roboto text-lg">{t('footer.mangojob')}</p><small className="text-xs text-gray-200">{t('footer.inc')}</small>
            </footer>
        </>
    );
}

export default Footer