import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { CookiesProvider } from "react-cookie"

import "./css/output.css"
import "./index.css"
import {COOKIES} from './common/const.js'
import {Head, HeadWithOgp} from "./components/head.js"
import { t } from './i18n/customi18n.js';
import CommonPageLayout from './page/layout/commonPageLayout';
import { SkeltonFullJobCard } from './components/skenton/fulljobcard.js';
import { SkeltonMiniJobCardList } from './components/skenton/minijobcard.js';
import { SkeltonSearchArea } from './components/skenton/searcharea.js';

import { Provider } from 'react-redux';
import store from './redux/store.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
const JobsIndex = React.lazy(() => import('./jobsIndex.js'));
const ContactComplete = React.lazy(() => import('./contactComplete.js'));
const ApplyComplete = React.lazy(() => import('./applyComplete.js'));
const NotFound = React.lazy(() => import('./NotFound.js'));
const Terms = React.lazy(() => import('./terms.js'));
const PrivacyPolicy = React.lazy(() => import('./privacypolicy.js'));
const Contact = React.lazy(() => import('./contact.js'));
const Apply = React.lazy(() => import('./apply.js'));
const SitemapTop = React.lazy(() => import('./page/sitemaptop.js'));
const SitemapAreaTop = React.lazy(() => import('./page/sitemapareatop.js'));
const About = React.lazy(() => import('./page/about.js'));
const Keep = React.lazy(() => import('./keep.js'));
const TopPage = React.lazy(() => import('./topPage.js'));
const FullJobCard = React.lazy(() => import('./top.js'));
// const ArticleContent = React.lazy(() => impor  t('./components/layout/article.js'))
const HireContent = React.lazy(() => import('./components/articles/hireArticle.js'))

const LazyLoadWrapper = React.memo(({ children, fallback = <div className='min-h-screen bg-white'></div> }) => {
  return (
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  );
})
LazyLoadWrapper.displayName = 'LazyLoadWrapper'

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return null;
}

root.render(
  <>
    <Provider store={store}>
      <HelmetProvider>
        <CookiesProvider>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<CommonPageLayout />}>
                <Route index element={<><HeadWithOgp /><LazyLoadWrapper><TopPage /></LazyLoadWrapper></>} />
                <Route path="jobs/:jobId" element={<><LazyLoadWrapper fallback={<SkeltonFullJobCard/>}><FullJobCard /></LazyLoadWrapper></>} />

                <Route path=":area/jobslist" element={<LazyLoadWrapper fallback={<><SkeltonSearchArea /><SkeltonMiniJobCardList /></>}><JobsIndex /></LazyLoadWrapper>} />
                <Route path="jobslist" element={<LazyLoadWrapper fallback={<><SkeltonSearchArea /><SkeltonMiniJobCardList /></>}><JobsIndex /></LazyLoadWrapper>} />
                <Route path="apply/:jobId" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.applyjobid")} /><LazyLoadWrapper><Apply /></LazyLoadWrapper></>} />
                <Route path="applyComplete" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.applycomplete")} /><LazyLoadWrapper><ApplyComplete /></LazyLoadWrapper></>} />
                <Route path="contact" element={<><HeadWithOgp pretitle={t("head.pretitle.contact")} /><LazyLoadWrapper><Contact /></LazyLoadWrapper></>} />
                <Route path="contactComplete" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.contactcomplete")} /><LazyLoadWrapper><ContactComplete /></LazyLoadWrapper></>} />
                <Route path="privacypolicy" element={<><HeadWithOgp pretitle={t("head.pretitle.privacypolicy")} /><LazyLoadWrapper><PrivacyPolicy /></LazyLoadWrapper></>} />
                <Route path="terms" element={<><HeadWithOgp pretitle={t("head.pretitle.termscondition")} /><LazyLoadWrapper><Terms /></LazyLoadWrapper></>} />
                <Route path="about" element={<><HeadWithOgp pretitle={t("head.pretitle.about")} /><LazyLoadWrapper><About /></LazyLoadWrapper></>} />
                <Route path="keepList" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.keeplist")} /><LazyLoadWrapper><Keep isKeepPage={true} cookieName={COOKIES.keeped}/></LazyLoadWrapper></>} />
                <Route path="viewList" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.viewlist")} /><LazyLoadWrapper><Keep isHistoryPage={true} cookieName={COOKIES.visited}/></LazyLoadWrapper></>} />
                <Route path="applyList" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.applylist")} /><LazyLoadWrapper><Keep isApplyPage={true} cookieName={COOKIES.applyed}/></LazyLoadWrapper></>} />
                <Route path="callList" element={<><HeadWithOgp noindex={true} pretitle={t("head.pretitle.calllist")} /><LazyLoadWrapper><Keep isCallPage={true} cookieName={COOKIES.called}/></LazyLoadWrapper></>} />

                <Route path="articles/hiregirls" element={<><HeadWithOgp pretitle={t("head.pretitle.hiregirls")} image={"/assets/happy.webp"}/><LazyLoadWrapper><HireContent /></LazyLoadWrapper></>} />

                <Route path="sitemap" element={<><HeadWithOgp pretitle={t("head.pretitle.sitemap")} /><LazyLoadWrapper><SitemapTop /></LazyLoadWrapper></>} />
                <Route path="sitemap/:area" element={<><HeadWithOgp pretitle={t("head.pretitle.sitemap")} /><LazyLoadWrapper><SitemapAreaTop /></LazyLoadWrapper></>} />
                <Route path="*" element={<><Head /><LazyLoadWrapper><NotFound/></LazyLoadWrapper></>} />
              </Route>
            </Routes>
          </Router>
        </CookiesProvider>
      </HelmetProvider>
    </Provider>
  </>
);
