import React from 'react';

const SkeltonMiniJobCard = React.memo(() => {
    return (
        <>
            <div className="bg-white rounded w-full animate-pulse mb-6">
                <div className='p-4'>
                    <div className="bg-gray-300 w-1/2 rounded h-8"></div>
                </div>            
                <div className="h-1 w-full"></div>
                <div className="h-[194px] w-full bg-gray-300"></div>
                <div className="p-4">
                    <div className="h-6 rounded w-3/4 bg-gray-300"></div>
                    <div className="h-6 rounded w-1/2 bg-gray-300 mt-2"></div>
                    <div className="h-6 rounded w-3/4 bg-gray-300 mt-2 mb-4"></div>

                    <div className="flex space-x-2">
                        <div className="h-14 bg-gray-300 w-full rounded"></div>
                        <div className="h-14 bg-gray-300 w-1/4 rounded"></div>
                    </div>
                </div>
            </div>
        </>
    );
})
SkeltonMiniJobCard.displayName = 'SkeltonMiniJobCard'

const SkeltonMiniJobCardList = React.memo(() => {
    return (
        <section className="p-4 md:pt-20 pt-16 bg-gray-100">
            <div className="h-9 w-full rounded" />
            {[1,2].map((index) => {
                return (
                <SkeltonMiniJobCard key={index}/>
                )
            })}
        </section>
    );
})
SkeltonMiniJobCardList.displayName = 'SkeltonMiniJobCardList'

export { SkeltonMiniJobCardList }